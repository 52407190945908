<template>
  <div>
    <h1 class="font-size-large text-color5">
      <span class="fw-700">TERMO DE CONDIÇÕES GERAIS DE LICENCIAMENTO, SERVIÇOS DE TECNOLOGIA E OUTRAS AVENÇAS</span>
    </h1>
    <p>Este termo (“TCG” ou o “Contrato”) estabelece as condições gerais de contratação aplicáveis aos licenciamentos de software da PIXEL ROADS LTDA., detentora dos direitos da plataforma Pixel Roads, pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 47.336.937/0001-04,estabelecina na  Rua Fernão Dias, 106, conj. 106, Pinheiros– São Paulo – SP CEP: 05.427-000, doravante denominada simplesmente “Pixel Roads”, ou (“LICENCIANTE”), e, de outro lado, o LICENCIADO qualificado no momento da compra dos produtos, doravante denominado simplesmente “LICENCIADO.” Pixel Roads e o LICENCIADO são denominados conjuntamente “Partes”.</p>

    <p>Outras Propostas Comerciais, Ordens de Compra ou quaisquer outros documentos posteriores, desde que expressamente aceitos e referenciando a vinculação a este Contrato (“Documentos Vinculados”), serão documentos hábeis à contratação de novas soluções pelo LICENCIADO e passarão a integrar este Contrato e, assim, tem este Contrato, natureza de “guarda-chuva” ou “máster” para todos os fins de direito.</p>

    <p>Este Contrato regerá todo e qualquer Documento Vinculado e estes não poderão alterar os termos e condições gerais estipulados neste TCG, que somente poderá ser alterado por meio de aditivo específico.</p>

    <p>O LICENCIADO deve estar ciente de que, ao fazer seus pedidos pelo website da Pixel Roads, estará, automaticamente, concordando com os Termos e Condições de Uso. A recusa na aceitação destes Termos e Condições de Uso impedirá que o LICENCIADO faça pedidos de serviços e campanhas em nosso website.</p>

    <p>Como condições precedentes, deve ser considerado que:</p>

    <p>i. Tem por objeto este Contrato o licenciamento uso de software de acesso remoto em caráter não exclusivo.<br/>
    ii. A Pixel Roads é um software de serviços que consolida diversos canais de mídia em uma plataforma integrada<br/>
    iii. Os algoritmos da LICENCIANTE utilizam dados e tecnologias de terceiros, de acordo com a LGDP, de forma anônima, em larga escala para entender interesses e prever comportamentos on-line;<br/>
    iv. O LICENCIADO possui interesse em adquirir o acesso à plataforma Pixel Road, tendo pleno conhecimento do produto e suas funcionalidades;<br/>
    v. O LICENCIADO declara ter lido e sido jurídica e tecnicamente assistido com relação a todas as disposições referentes à natureza e disposições específicas deste Contrato, tratando-se de pessoa jurídica sem qualquer tipo de hipossuficiência ou vulnerabilidade em relação à LICENCIANTE;<br/>
    vi. O signatário deste Contrato, ou aquele que expressamente o aceitou em nome do LICENCIADO, declara ter poderes suficientes de representação para a contratação, em conformidade com as políticas internas do LICENCIADO;</p>

    <p>De acordo com as condições precedentes, LICENCIANTE e LICENCIADO firmam este Contrato consoante os seguintes termos e condições:</p>

    <h2>1. OBJETO DO CONTRATO</h2>
    <p>1.1 Por meio deste Termo, a LICENCIANTE se compromete a disponibilizar software de acesso remoto Pixel Roads em caráter não exclusivo.</p>
    <p>1.2 A Pixel Roads é um software de serviços que conecta uma empresa aos seus potenciais consumidores através de mídia programática, através da criação de campanhas online personalizadas, com seleção de público-alvo e objetivos de performance, para alcançar potenciais consumidores.</p>

    <h2>2. DEFINIÇÕES COMPLEMENTARES</h2>
    <p>2.1 Para fins destes Termos e Condições de Compra, aplicam-se as seguintes definições:<br/>
    a) Cadastro: Ato de o visitante inserir seus dados para se cadastrar na plataforma e ter acesso às áreas e funcionalidades restritas a clientes.<br/>
    b) ID Number: número único e intransferível para cada CNPJ ou CPF cadastrado na plataforma.<br/>
    c) Usuário: Todo visitante autenticado no endereço eletrônico da Pixel Roads.<br/>
    d) Visitante: Qualquer pessoa que navegar no endereço eletrônico da Pixel Roads.</p>

    <h2>3. OBRIGAÇÕES DA LICENCIANTE</h2>
    <p>3.1 Sem prejuízo das demais obrigações dispostas neste Contrato e anexos, a LICENCIANTE se obriga à:</p>

    <p>(i) Fornecer ao LICENCIADO o acesso à Plataforma Pixel Road;<br/>
    (ii) Prestar ao LICENCIADO Suporte Técnico da Plataforma Pixel Road nos termos do Contrato.</p>

    <p>3.2 Serviços diversos de tecnologia da informação, de consultoria - incluindo treinamentos e customizações da Plataforma Pixel Road, e outros poderão ser contratados pelo LICENCIADO mediante a assinatura de um contrato específico entre as partes e o pagamento de remuneração adicional. </p>

    <p>3.3 O presente Contrato não inclui o fornecimento de hardware, equipamentos, peças sobressalentes, ou qualquer outro software diverso da Plataforma Pixel Road e/ou serviços de manutenção/conserto.</p>

    <h2>4. OBRIGAÇÕES DO LICENCIADO</h2>

    <p>4.1 Sem prejuízo das demais obrigações dispostas neste Contrato e nos anexos, porventura firmados entre as partes, o LICENCIADO se obriga à:</p>

    <p>(i) Fazer uso da Plataforma Pixel Road conforme a sua destinação e sempre de acordo com o(s) respectivo(s) manual(is) e disposições do Contrato, Termos de Uso e anexos;
    (ii) Pagar os valores relativos aos Serviços providos por intermédio da Plataforma Pixel Road e Serviços Relacionados.</p>

    <p>4.2 Veracidade das Informações. Para utilizar os serviços oferecidos, o LICENCIADO deverá fornecer todas as informações solicitadas no CADASTRO, assumindo total responsabilidade, tanto cível quanto criminal, pela exatidão e veracidade dos dados fornecidos. Essas informações poderão ser verificadas a qualquer momento por meio do site da Pixel Roads. Ao se cadastrar na Pixel Roads, o usuário assume a responsabilidade pela veracidade das informações fornecidas e compromete-se a realizar o cadastro corretamente.</p>

    <p>4.3. Em caso de informações incorretas, falsas ou não confirmadas, ou caso haja recusa em corrigir ou fornecer a documentação necessária para a correção, a Pixel Roads se reserva o direito de não concluir o processo de cadastro, e bloquear um cadastro existente, impedindo o LICENCIADO de utilizar os serviços online até que a situação seja regularizada, conforme critérios da Pixel Roads.</p>

    <p>4.3.1. A Pixel Roads também poderá, a seu critério impedir novos cadastros, ou cancelar cadastros já efetuados, caso sejam detectadas irregularidades ou ilegalidades que considere graves, ou em situações em que o LICENCIADO tenha tentado burlar as regras estabelecidas. Caso o LICENCIADO descumpra qualquer uma das obrigações previstas neste Termo, a Pixel Roads adotará o mesmo procedimento descrito acima.</p>

    <p>4.4.  Acesso e Responsabilidade. Após o cadastro ser concluído com sucesso, o LICENCIADO terá acesso aos serviços mediante login e senha. Esses dados são de uso exclusivo do LICENCIADO, que se compromete a não os divulgar a terceiros, assumindo a responsabilidade por qualquer solicitação de serviço feita com o uso de seu login e senha.</p>

    <p>4.5. Solicitação e Armazenamento de Dados. A Pixel Roads solicita apenas as informações pessoais essenciais para a prestação dos serviços escolhidos pelo LICENCIADO. Essas informações são obtidas de forma justa e legal, com o consentimento obrigatório do LICENCIADO.</p>

    <p>4.6. Se houver armazenamento de dados, a Pixel Roads compromete-se a adotar medidas de segurança comercialmente aceitáveis para proteger as informações contra perdas, roubos, acesso não autorizado, divulgação, cópia, uso ou modificação.</p>

    <p>4.7. Compromisso do Usuário. O usuário compromete-se a utilizar os conteúdos e as informações fornecidas pela Pixel Roads de maneira adequada e em conformidade com as seguintes regras:</p>

    <p>a) Não se envolver em atividades ilegais ou contrárias à boa fé e à ordem pública.<br/>
    b) Não divulgar propaganda ou conteúdo de natureza racista, xenofóbica, relacionado a apostas online, jogos de azar, pornografia ilegal, apologia ao terrorismo ou qualquer material que viole os direitos humanos. Conteúdos sensíveis, como criptomoedas, Bitcoin ou NFTs, que ainda estejam sujeitos a regulamentação, também podem ser bloqueados.<br/>
    c) Não causar danos aos sistemas físicos (hardware) e lógicos (software) da Pixel Roads, seus fornecedores ou terceiros, nem disseminar vírus ou sistemas que possam causar esses danos.<br/>
    d)Fornecer dados verdadeiros, corretos e atualizados. Caso forneça informações falsas, inválidas, incorretas ou de terceiros sem autorização, a Pixel Roads se reserva o direito de DESCADASTRAR IMEDIATAMENTE A CONTA E CANCELAR AS CAMPANHAS, sem aviso prévio, além de adotar outras medidas cabíveis.</p>

    <p>4.8. Comunicação com o Usuário. O USUÁRIO aceita que a Pixel Roads envie mensagens por e-mail, WhatsApp e/ou SMS para o número de celular cadastrado, com caráter informativo e referentes a comunicações específicas sobre os serviços, incluindo informações sobre novos recursos e ofertas.</p>

    <h2>5. PREÇO E FORMA DE PAGAMENTO</h2>
    <p>5.1. Remuneração. Em contraprestação a execução do objeto do presente Contrato, será devido o valor descrito em proposta comercial, a ser pago nas condições ali definidas, respeitando-se as demais cláusulas do presente Contrato e anexos.</p>

    <p>5.2 Em caso de não pagamento ou atraso injustificado por parte do LICENCIADO dos valores e prazos acordados, fica sujeita ao pagamento de multa moratória de 2% (dois por cento), acrescidos de correção monetária calculada “pro rata die” através do índice IGP/M -Fundação Getúlio Vargas e juros de mora equivalentes a 1% (um por cento) ao mês sobre os valores indicados neste Contrato e nos anexos.</p>

    <p>5.2.1 No caso acima a LICENCIANTE poderá ainda:</p>

    <p>i) Bloquear o uso da Plataforma Pixel Road pelo LICENCIADO ou por quaisquer Usuários a ela vinculados até a regularização dos pagamentos, respeitando-se a prévia notificação extrajudicial, não sanada no prazo de 2 (dois) dias contados do efetivo recebimento da referida notificação;<br/>
    ii) Tomar as medidas cabíveis para receber o crédito, respeitando-se previamente o disposto no item (i) acima; e<br/>
    iii)  Rescindir o Contrato se o atraso ultrapassar 10 (dez), respeitando-se previamente o disposto no item (i) acima.</p>

    <h2>6. PRAZO DE VIGÊNCIA E RESCISÃO DO CONTRATO</h2>
    <p>6.1 O presente instrumento tem validade indeterminada e a Pixel Roads poderá revisar estes termos de serviço do site a qualquer momento, sem aviso prévio. Ao usar este site, concorda em ficar vinculado à versão atualizada destes termos de serviço.</p>

    <p>6.2 Rescisão Contratual. Além de outras causas previstas na legislação em vigor, este Contrato, poderá ser rescindido imediatamente, sem qualquer ônus para a parte inocente, na ocorrência das seguintes hipóteses: </p>

    <p>(i) Diante de descumprimento ou cumprimento irregular das obrigações deste Contrato e seus anexos, devidamente comprovado, que não seja sanado, se sanável, e prazo razoável após o recebimento de notificação enviada pela Parte prejudicada e sem justificativa;<br/>
    (ii) Quando houver a declaração de falência, dissolução/liquidação judicial ou extrajudicial de quaisquer das Partes;<br/>
    (iii) Na inobservância, descumprimento ou cumprimento irregular das obrigações em relação à propriedade intelectual da Plataforma Pixel Road.</p>

    <p>6.3 Exclusão pós-rescisão. A LICENCIADO está ciente e concorda que todos os dados monitorados e armazenados serão deletados no momento da rescisão do Contrato, respeitando-se o disposto na Lei nº 13.709/18, devendo a LICENCIADO tomar previamente, ou seja, durante a vigência plena do Contrato, quaisquer providências de backup espontâneo destes Dados.</p>

    <p>6.4 Pós extinção. Todas as previsões de propriedade intelectual, confidencialidade, remuneração e limitações de responsabilidade permanecerão vigentes mesmo após a extinção do Contrato.</p>

    <h2>7. DESEMPENHO, RESULTADOS E LIMITAÇÕES DE RESPONSABILIDADE</h2>
    <p>7.1 Desempenho e Resultados. O LICENCIADO reconhece que a LICENCIANTE não assume qualquer responsabilidade perante o LICENCIADO ou terceiros em relação à rentabilidade comercial da Plataforma Pixel Road, nem quanto à sua adequação para um determinado negócio ou finalidade. Mesmo utilizando todos os meios e informações oferecidos pela LICENCIANTE, esta não garante a absoluta veracidade e precisão dos dados inseridos pelo LICENCIADO, os quais são utilizados para alimentar a Plataforma Pixel Road.</p>

    <p>7.1.1. O LICENCIADO concorda expressamente que a LICENCIANTE não será responsabilizada por quaisquer danos decorrentes de informações obtidas na Plataforma Pixel Road, estando ciente de que as decisões de negócio e gestão tomadas pelo LICENCIADO não devem se basear exclusivamente nos dados da Plataforma.</p>

    <p>7.1.2.  A LICENCIANTE não será responsável por falhas em hardwares ou softwares de propriedade ou gerência do LICENCIADO que possam impactar, impedir ou prejudicar a prestação dos serviços.</p>

    <p>7.1.3. A Garantia sobre o Produto. A Pixel Roads responsabiliza-se somente pelas garantias e orientações fornecidas através de seu website e demais informações previstas nas descrições dos seus produtos e serviços ora comercializados.</p>

    <p>7.1.4. A Pixel Roads, por este instrumento, não reconhece a existência de qualquer garantia implícita ou explícita ao LICENCIADO ou a terceiros relativamente aos seus produtos. A Pixel Roads também não reconhece a existência de quaisquer garantias implícitas, incluindo, mas não limita as garantias relativas a direitos, comercialização ou conveniência para fins específicos para os produtos.</p>

    <p>7.2. Exclusões Expressas. A LICENCIANTE não será responsável, em nenhuma hipótese, por:</p>

    <p>(i) Serviços cuja execução seja de responsabilidade do LICENCIADO ou de terceiros contratados por ele;  <br/>
    (ii) Serviços executados por terceiros, desde que tal fato seja de conhecimento do LICENCIADO, exceto em casos de dolo ou culpa da Pixel Road;  <br/>
    (iii) Danos resultantes da falha do LICENCIADO em desempenhar suas obrigações previstas neste Contrato e em seus anexos; e  <br/>
    (iv) Perdas indiretas ou intangíveis, incluindo, mas não se limitando a lucros cessantes.</p>

    <p>7.3 Limitação de Responsabilidade. Em nenhuma circunstância a indenização devida pela LICENCIANTE ao LICENCIADO excederá o valor total efetivamente pago pelo LICENCIADO à LICENCIANTE, conforme este Contrato. O LICENCIADO reconhece e concorda que as limitações de responsabilidade estabelecidas foram determinantes para a definição dos valores contratuais.</p>

    <p>7.4 Responsabilidades do LICENCIADO. O LICENCIADO é inteiramente responsável por quaisquer atos, fatos ou omissões de seus sócios, empregados, prepostos, sublicenciados ou subcontratados que venham a causar danos ou prejuízos à LICENCIANTE e/ou a terceiros, ou que infrinjam qualquer disposição deste Contrato ou de seus anexos.</p>

    <p>7.5 Conteúdo Inserido na Plataforma Pixel Road. A LICENCIANTE não é obrigada a monitorar o conteúdo inserido pelo LICENCIADO na Plataforma Pixel Road, incluindo materiais, fotos e outros dados. A veracidade e legalidade das informações fornecidas são de responsabilidade exclusiva do LICENCIADO, não cabendo à LICENCIANTE responder por conteúdos ilegais, imorais ou antiéticos. O LICENCIADO será o único responsável por eventuais reclamações de terceiros ou ações judiciais relacionadas a tais conteúdos.</p>

    <p>7.5.1 Não é permitido veicular anúncios que:</p>

    <p>(i) Violem a lei, moral, bons costumes, propriedade intelectual, ou direitos à honra, privacidade, imagem ou intimidade;  <br/>
    (ii) Estimulem condutas ilícitas ou contrárias à moral e aos bons costumes;  <br/>
    (iii) Incitem discriminação com base em sexo, raça, origem, religião, crenças, idade ou qualquer outra condição;  <br/>
    (iv) Disponibilizem ou incentivem o acesso a conteúdos ilícitos, violentos ou degradantes;  <br/>
    (v) Enviem e-mails em massa (SPAM) com ofertas de produtos ou serviços sem o consentimento dos destinatários;  <br/>
    (vi) Induzam a estados de ansiedade ou medo inaceitáveis;  <br/>
    (vii) Promovam práticas perigosas ou nocivas à saúde ou ao equilíbrio mental;  <br/>
    (viii) Propaguem conteúdos falsos, ambíguos ou exagerados que possam induzir ao erro; <br/>
    (ix) Violem o sigilo das comunicações;  <br/>
    (x) Constituam publicidade enganosa ou desleal, ou concorrência desleal;  <br/>
    (xi) Promovam, incitem ou estimulem a pedofilia;  <br/>
    (xii) Incorporem vírus ou elementos que possam prejudicar sistemas de terceiros;  <br/>
    (xiii) Tentem obter acesso não autorizado a sistemas ou redes;  <br/>
    (xiv) Reproduzam, vendam ou distribuam produtos sem a devida autorização e pagamento de direitos autorais.</p>

    <p>7.6. O USUÁRIO assume total responsabilidade por qualquer uso inadequado da Plataforma Pixel Roads, sendo responsável por quaisquer danos ou prejuízos decorrentes da violação das obrigações estabelecidas neste documento.</p>

    <h2>8. PROPRIEDADE INTELECTUAL</h2>
    <p>8.1 Proteção da “Plataforma Pixel Road” e outros. Os direitos de propriedade intelectual da Plataforma Pixel Road inclusive Customizações solicitadas ou não pelo LICENCIADO e demais materiais intelectuais que os compõem, são de propriedade exclusiva da LICENCIANTE, não tendo o LICENCIADO qualquer autorização para agir em nome da LICENCIANTE ou promover qualquer registro em autoridade competente. Qualquer violação ou uso não autorizado promovido pelo LICENCIADO ou com contribuição desta implicará na aplicação de sanções e multas previstas no Contrato, sem prejuízo de desdobramentos na esfera cível e criminal, além de indenizar a LICENCIANTE pelas perdas e danos.</p>

    <p>8.1.1 Nenhuma disposição contida neste Contrato deve ser interpretada como uma transferência ou cessão de titularidade de quaisquer direitos de propriedade intelectual relacionados ao Software da LICENCIANTE, incluindo, entre outros, quaisquer direitos de propriedade intelectual sobre criações/invenções posteriores à assinatura do Contrato.</p>

    <p>8.2. Propriedade de Resultados. Nada neste Contrato deve ser considerado como cessão ou transferência da propriedade da base de dados da LICENCIADA à LICENCIANTE, sendo certo que todas e quaisquer informações resultantes do tratamento de Dados Pessoais realizado pela LICENCIANTE sob este Contrato, incluindo quaisquer inferências geradas a partir de um Dado Pessoal serão de propriedade exclusiva da LICENCIADA.</p>

    <p>8.3. O LICENCIADO não é autorizado, em nenhuma hipótese, a utilizar quaisquer dos materiais deste website ou o próprio website, para fins comerciais, sem que a Pixel Roads lhe conceda uma licença específica para esse fim. Nenhuma parte deste website pode ser reproduzida e/ou armazenada em qualquer outro website ou incluída em qualquer outro sistema ou serviço de recuperação eletrônico público ou privado, sem a autorização prévia por escrito da Pixel Roads. Qualquer reprodução de uma obra autoral de um autor, seja ela literária, artística ou científica, sem a sua devida autorização, é considerada como uma cópia não autorizada.</p>

    <h2>9. CONFIDENCIALIDADE</h2>
    <p>9.1. Informações confidenciais. As Partes obrigam-se a tratar como confidenciais todas as informações fornecidas e que tenham acesso em função do presente Contrato, independente do suporte ou formato recebido “Informações Confidenciais”. As Informações Confidenciais que foram reveladas durante a vigência deste Contrato deverão ser mantidas em sigilo tanto enquanto perdurar tal vigência como após o término do Contrato. As informações confidenciais serão consideradas segredos de negócio para os fins e efeitos do Artigo 195, Inciso XI, da Lei nº 9.279/96.</p>

    <p>9.2. Diligência e vigilância. As Partes se comprometem a não revelar, reproduzir, utilizar ou dar conhecimento das informações comerciais a terceiros estranhos ao presente Contrato. As Informações Confidenciais devem ficar restritas a pessoas que estão diretamente ligadas ao projeto e necessitam das mesmas para a realização das disposições previstas neste Contrato.</p>

    <p>9.3. Hipótese de resolução. A divulgação das Informações Confidenciais definidas nesta cláusula pode incorrer na resolução imediata do Contrato sem ônus para a Parte que foi prejudicada pela divulgação.</p>

    <p>9.4. Exceções à confidencialidade. As obrigações de confidencialidade acima descritas não se aplicam a qualquer informação que:</p>

    <p>(i) Seja de domínio público no momento de sua revelação à Parte receptora, ou que passem a ser de domínio público sem que a esta, seus diretores, acionistas ou quotistas tenham contribuído para isso;<br/>
    (ii) Estejam na posse da Parte receptora, seus diretores, acionistas ou quotistas no momento de sua revelação; e<br/>
    (iii) Sejam desenvolvidas independentemente pelas Partes sem o uso de Informação Confidencial.</p>

    <p>9.5. Divulgação judicial de Informações Confidenciais. Caso as Informações Confidenciais devam ser divulgadas em virtude de lei ou ordem judicial, a Parte que receber a ordem deverá comunicar à Parte titular da Informação, antes da divulgação, para que esta adote as medidas que entender necessárias para evitar a revelação, desde que não acarrete nenhum prejuízo à Parte titular da Informação. Se a divulgação da Informação Confidencial não puder ser evitada, a Parte que tem a obrigação legal de revelá-la deverá fazê-lo nos exatos limites determinados na norma legal ou na referida ordem judicial.</p>

    <h2>10. GUARDA E TRATAMENTO DE DADOS E REGISTROS</h2>
    <p>10.1. Propriedade dos Dados. O LICENCIADO é e permanecerá como titular exclusivo de seus dados, sendo também responsável por quaisquer dados de terceiros inseridos na Plataforma Pixel Road, em conformidade com este Contrato, independentemente da natureza ou origem dos dados ("Dados").</p>

    <p>10.2. Confidencialidade e Tratamento dos Dados. As Partes comprometem-se a manter em sigilo absoluto todos os Dados acessados em razão deste Contrato, em estrita observância à Lei Geral de Proteção de Dados (LGPD – Lei nº 13.709/2018) e suas respectivas atualizações.</p>

    <p>10.3. Coleta e Uso dos Dados Fornecidos pelo Usuário. Os dados fornecidos pelo Usuário dizem respeito às informações voluntariamente disponibilizadas ao interagir, contratar ou utilizar os serviços oferecidos pela Pixel Roads. Tais dados pessoais serão solicitados apenas quando estritamente necessários à prestação de um serviço específico. A coleta ocorre de maneira justa e lícita, com o consentimento expresso do Usuário, sendo esclarecido o propósito e a forma de utilização dos dados coletados.</p>

    <p>10.4. As informações serão retidas apenas pelo tempo necessário à execução dos serviços contratados, e a Pixel Roads adota todas as medidas comercialmente adequadas para assegurar a proteção dessas informações contra perda, roubo, acesso não autorizado, divulgação, uso ou modificação indevida.</p>

    <p>10.5. Para a exibição de anúncios personalizados conforme as preferências do Usuário, a Pixel Roads utiliza plataformas de terceiros que processam dados pessoais pseudonimizados diretamente de websites ou aplicativos móveis. Esses dados são tratados por algoritmos avançados, de modo a garantir que nenhuma informação pessoal identificável seja coletada.</p>

    <p>10.6. Os parceiros comerciais coletam dados de navegação anônimos, como informações de localização geográfica, e utilizam cookies, endereços IP e dados anônimos de dispositivos para o rastreamento. A responsabilidade pela coleta e segurança desses dados é integralmente dos parceiros, sendo que a Pixel Roads não tem acesso para decodificá-los, garantindo um processo seguro e anônimo.</p>

    <p>10.7. Adicionalmente, a Pixel Roads não compartilha informações pessoais identificáveis com o público ou terceiros, salvo quando exigido por força de lei. O site pode conter links para páginas externas, cujas práticas de privacidade e conteúdo não são controladas pela Pixel Roads, que, por conseguinte, não assume qualquer responsabilidade sobre eles.</p>

    <p>10.8. O Usuário tem a liberdade de recusar o fornecimento de informações pessoais, ciente de que tal recusa poderá limitar o acesso a determinados serviços. A utilização contínua da plataforma será considerada aceitação das práticas relacionadas à privacidade e tratamento de informações pessoais. Eventuais dúvidas podem ser dirigidas ao e-mail  falecom@pixelroads.com.br.</p>

    <p>10.9. Nos termos da legislação vigente, a Pixel Roads pode ter acesso às seguintes categorias e tipos de dados pessoais, respeitando os princípios da finalidade, adequação, necessidade, transparência e segurança:</p>

    <p>a) Dados de Pessoa Física: Nome, estado civil, profissão, CPF, RG, data de nascimento, e-mail, número de telefone, endereço, entre outros dados necessários à contratação dos serviços.
    b) Dados de Pessoa Jurídica: Razão social, CNPJ, RG, CPF e nome do representante legal, além de outros dados indispensáveis à contratação, prospecção ou cancelamento de produtos e serviços. <br/>
    c) Informações Coletadas Automaticamente: Endereço IP, local, data e hora de acesso, histórico de navegação, geolocalização, fonte de referência, tipo de navegador, tempo de permanência e páginas visitadas.</p>

    <p>10.10. O Usuário é o único responsável pela exatidão e veracidade dos dados pessoais fornecidos à Pixel Roads durante o processo de cadastro. A Pixel Roads não se responsabiliza pela veracidade das informações fornecidas.</p>

    <p>10.11. Coleta de Dados Durante a Navegação de Serviços. Durante o uso dos nossos serviços, seja como visitante ou usuário, após consentimento expresso para esta Política, são coletados dados de navegação. Tais informações abrangem interações com nosso aplicativo, portal e redes sociais, incluindo:</p>

    <p>- Endereço IP;  <br/>
    - Histórico de buscas e navegação;  <br/>
    - Datas e horários de acesso;  <br/>
    - Funcionalidades e recursos utilizados;  <br/>
    - Forma de uso dos produtos e serviços;  <br/>
    - Registros de erros ou falhas relacionadas ao sistema.</p>

    <p>10.11.1. Esses dados são essenciais para garantir o melhor desempenho das funcionalidades oferecidas, proporcionando uma experiência de navegação personalizada.</p>

    <p>10.11.2. Em alguns casos, a coleta ocorre por meio de cookies ou tecnologias semelhantes, essenciais ao pleno funcionamento dos nossos serviços. A recusa ou exclusão dos cookies poderá impactar a disponibilidade e a funcionalidade dos serviços prestados pela Pixel Roads.</p>

    <h2>11. CUMPRIMENTO DE NORMAS</h2>
    <p>11.1. As Partes declaram ter compromisso para com todas as responsabilidades sociais, laborais e ambientais aplicáveis às suas atividades e de acordo com as melhores práticas nacionais e internacionais e legislação relevante. Não são toleradas quaisquer formas de corrupção, discriminação, assédio, trabalho escravo ou análogo a escravo, trabalho infantil ou quaisquer práticas contrárias à legislação do respectivo país de atuação, ou que possam ser consideradas imorais, suspeitas ou antiéticas sob pena de rescisão contratual.</p>

    <p>11.2. As Partes declaram neste ato que estão cientes, conhecem, entendem e respeitam os termos das leis anticorrupção brasileiras, em especial a Lei n° 12.846/13 e seu respectivo Decreto n° 8.420/15, comprometendo-se a abster-se de qualquer atividade que constitua uma violação das disposições destas Regras Anticorrupção e dos demais ordenamentos jurídicos.</p>

    <p>11.3. A Parte que violar quaisquer leis anticorrupção aplicáveis deverá indenizar a outra Parte pelas perdas e danos que esta sofrer em razão de sua violação.</p>

    <h2>12. DISPOSIÇÕES GERAIS</h2>
    <p>12.1 Quaisquer alterações deste Contrato deverão ser feitas por escrito e mediante aditivo contratual.</p>

    <p>12.2 Hierarquia de entendimentos. As Partes declaram conjuntamente que na hipótese de conflitos ou contradições, os entendimentos do presente Contrato e seus anexos prevalecem sobre quaisquer outros entendimentos preliminares, verbais ou escritos, que mantiveram entre si.</p>

    <p>12.3 As Partes são licenciados independentes e o presente Contrato não pressupõe nem constitui qualquer sociedade, joint venture, representação comercial ou agenciamento entre as Partes.</p>

    <p>12.4 Desvinculação trabalhista. Não se estabelece, por força deste Contrato, direta ou indiretamente, qualquer vínculo empregatício, obrigação, ou responsabilidade entre uma Parte com a outra com relação aos profissionais que a outra Parte disponibilizar para a execução do objeto deste Contrato, correndo por conta exclusiva de cada Parte todos os encargos decorrentes de legislação vigente sob seus próprios colaboradores, seja trabalhista, previdenciária, securitária, civil ou quaisquer outros que vierem a ser criados pelos Órgãos Públicos, sindicatos e entidades representativas das categorias.</p>

    <p>12.5 Cessão. A LICENCIANTE fica desde já autorizada a ceder a terceiros, ao seu critério, os direitos creditórios oriundos do Contrato e a realizar a antecipação de eventuais recebíveis junto às instituições financeiras e de crédito, mediante simples comunicação ao LICENCIADO.</p>

    <p>12.5.1 Com exceção da hipótese acima, o presente Contrato, seus direitos e suas obrigações não poderão ser cedidos ou transferidos, total ou parcialmente pelas Partes a terceiros, sem consentimento prévio e por escrito da parte contrária sob pena de nulidade da cessão e da rescisão unilateral e de pleno direito deste Contrato.</p>

    <p>12.6 Uso da Marca. O LICENCIADO autoriza expressamente a utilização de seus logotipos nos ambientes digitais da LICENCIANTE para fins de divulgação, especialmente na Plataforma Pixel Road, relatórios e apresentações inerentes aos Serviços, materiais de prospecção digitais ou impressos dela, além de sites e aplicativos que remetam a ela, pelo tempo que durar o Contrato, de modo gratuito e mundial.</p>

    <p>12.6.1 Para fins de marketing e publicação ou negociação de serviços e/ou para fins de apresentação a licenciados para uso interno, as Partes poderão divulgar o fato de ter prestado/tomado serviços da outra Parte, identificando-a pelo nome e/ou pelo seu logotipo e indicando apenas a natureza geral ou categoria desses serviços e quaisquer detalhes que se tornarem licitamente de domínio público.</p>

    <p>12.7 Força maior. Qualquer evento de caso fortuito ou força maior, incluindo ataques cibernéticos invasivos diretos ou indiretos de terceiros, não sujeitará nenhuma Parte a qualquer sanção, considerando o compromisso de ambas as partes no esforço do contorno do incidente o mais rápido possível. Nesse caso o Contrato ficará suspenso por até 30 (trinta) dias e poderá ser rescindido pelas Partes sem ônus.</p>

    <p>12.7.1 Se qualquer das Partes ficar temporariamente impedida de cumprir suas obrigações, no todo ou em parte, em consequência de caso fortuito ou de força maior, deverá comunicar o fato de imediato à outra Parte e ratificar por escrito a comunicação em até 2 (dois dias) úteis, informando os efeitos danosos do evento.</p>

    <p>12.8 Contato e suporte. O LICENCIADO dispõe de canal para sanar suas dúvidas, solucionar eventuais solicitações ou reclamações atinentes ao seu pedido ou outras informações disponibilizadas no website. O FALE CONOSCO poderá ser acionado por meio de envio de e-mail para falecom@pixelroads.com.br.</p>

    <p>12.9 Foro e Legislação. O Contrato será regido pela legislação da República Federativa do Brasil e as Partes elegem o Foro Central da Comarca de São Paulo, Estado de São Paulo, com expressa renúncia de qualquer outro, para dirimir eventuais questões relativas ao presente Contrato.</p>

    <p>E, as Partes expressamente declaram anuir, autorizar e reconhecer que o presente Contrato poderá, alternativamente, ser assinado eletronicamente por seus representantes legais, nos termos dos artigos 219 e 220 do Código Civil, e do artigo 10, parágrafos 1º e 2º da Medida Provisória nº 2.200, de 24 de agosto de 2001. Neste sentido, as Partes reconhecem a veracidade, autenticidade, integridade, validade e eficácia deste CONTRATO e de todos os seus termos, para todos os fins de direito.</p>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Termos de Uso e Política de Privacidade'
  }
}
</script>
